import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['submit', 'eligibleWitness'];

  connect() {
    // Initial state - ensure submit is disabled if checkbox exists and is unchecked
    this.toggleSubmitButton();
  }

  eligibleWitnessTargetConnected(element) {
    this.toggleSubmitButton();
  }

  eligibleWitnessTargetDisConnected(element) {
    this.toggleSubmitButton();
  }

  toggleSubmitButton() {
    // Enable/disable submit button based on eligibility state
    this.submitTarget.disabled = !this.hasEligibleWitnessTarget;
  }
}
