import { ApplicationController, useDebounce } from 'stimulus-use';

export default class FormComponentController extends ApplicationController {
  static debounces = [{ name: 'fetchTxHashAmount', wait: 300 }];

  static targets = [
    'poolSelect',
    'categorySelect',
    'amountInput',
    'txHashInput',
  ];

  static values = {
    poolsAndAmounts: Object,
    fetchTxUrl: String,
    pool: String,
    category: String,
  };

  static outlets = ['forms--new-text-field'];

  connect() {
    useDebounce(this);
  }

  formsNewTextFieldOutletConnected() {
    this.configureAmountInput();
  }

  updatePool() {
    this.poolValue = this.poolSelectTarget.value;
    const { maxAmount } = this.poolSelectTarget.options[this.poolSelectTarget.selectedIndex].dataset;
    if (maxAmount) {
      this.formsNewTextFieldOutlet.setAddOnValue(maxAmount);
    }
    this.configureAmountInput();
  }

  updateCategory() {
    this.categoryValue = this.categorySelectTarget.value;
    this.configureAmountInput();
  }

  configureAmountInput() {
    switch (this.categoryValue) {
      case 'contribution':
        this.amountInputTarget.placeholder = 'Enter amount to contribute';
        this.amountInputTarget.max = '';
        this.formsNewTextFieldOutlet.hideAddOn();
        break;
      case 'other_addition':
        this.amountInputTarget.placeholder = 'Enter amount to add';
        this.amountInputTarget.max = '';
        this.formsNewTextFieldOutlet.hideAddOn();
        break;
      case 'refund':
        this.amountInputTarget.placeholder = 'Enter amount to refund';
        this.amountInputTarget.max = this.maxInputAmount;
        this.formsNewTextFieldOutlet.showAddOn();
        break;
      case 'other_deduction':
        this.amountInputTarget.placeholder = 'Enter amount to deduct';
        this.amountInputTarget.max = this.maxInputAmount;
        this.formsNewTextFieldOutlet.showAddOn();
        break;
      default:
        this.amountInputTarget.placeholder = 'Enter amount';
        this.amountInputTarget.max = this.maxInputAmount;
        this.formsNewTextFieldOutlet.hideAddOn();
        break;
    }
  }

  get maxInputAmount() {
    return this.poolsAndAmountsValue[this.poolValue]?.toString() || '';
  }

  async fetchTxHashAmount(event) {
    event.preventDefault();
    const txHash = this.txHashInputTarget.value;

    // As long as the user has inputted a tx hash, the amount input should be set to readonly
    // and the value should be cleared and finally set by the result of the fetched result
    if (txHash.length > 0) {
      this.amountInputTarget.readOnly = true;
      this.amountInputTarget.value = '';
    } else {
      this.amountInputTarget.readOnly = false;
    }

    if (this.isValidTxHash(txHash)) {
      const response = await fetch(`${this.fetchTxUrlValue}?tx_hash=${txHash}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });

      const { success, amount, errors } = await response.json();

      if (success) {
        this.amountInputTarget.value = amount;

        // Call "keyup->forms--text-field#handleValidateInput" action from input target
        const textFieldController =
          this.application.getControllerForElementAndIdentifier(
            this.amountInputTarget.parentElement,
            'forms--text-field'
          );
        textFieldController?.handleFocusInput();
        textFieldController?.handleValidateInput();
      } else {
        console.error(errors);
      }
    }
  }

  isValidTxHash(txHash) {
    return /^0x([A-Fa-f0-9]{64})$/.test(txHash);
  }
}
