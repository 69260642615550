import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['content']
  static values = {
    timeout: Number
  }

  connect() {
    if (this.hasContentTarget) {
      this.startTimeout()
    }
  }

  disconnect() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  startTimeout() {
    this.timeoutId = setTimeout(() => {
      this.showContent()
    }, this.timeoutValue)
  }

  showContent() {
    this.contentTarget.classList.remove('hidden')
  }
} 