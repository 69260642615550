import { Controller } from 'stimulus'
import gsap from 'gsap'

export default class extends Controller {
  static targets = ['muted', 'image', 'button', 'logo', 'description']

  connect() {
    this.resetAnimation()
    this.initializeAnimation()

    // Add resize listener for responsive behavior
    this.boundCheckScreenSize = this.checkScreenSize.bind(this)
    window.addEventListener('resize', this.boundCheckScreenSize)
    document.addEventListener('turbo:before-visit', this.cleanup.bind(this))

    // Check initial screen size
    this.checkScreenSize()
  }

  disconnect() {
    this.cleanup()
    window.removeEventListener('resize', this.boundCheckScreenSize)
    document.removeEventListener('turbo:before-visit', this.cleanup.bind(this))
  }

  cleanup() {
    this.element.removeEventListener('mouseenter', this.boundAnimationPlay)
    this.element.removeEventListener('mouseleave', this.boundAnimationReverse)
    if (this.timeline) {
      this.timeline.kill()
      this.timeline = null
    }
  }

  checkScreenSize() {
    const isMobile = window.innerWidth < 700

    if (isMobile && this.timeline) {
      this.timeline.play()
    }
  }

  resetAnimation() {
    // Reset animation states while preserving background
    gsap.set(this.imageTarget, {
      scale: 1,
      clearProps: "transform"
    })

    gsap.set(this.logoTarget, {
      y: 0,
      clearProps: "transform"
    })

    gsap.set(this.descriptionTarget, {
      y: 10,
      opacity: 0
    })

    gsap.set([this.mutedTarget, this.buttonTarget], {
      opacity: 0
    })
  }

  initializeAnimation() {
    // Force GPU acceleration on elements that will animate
    gsap.set([this.imageTarget, this.logoTarget, this.descriptionTarget], {
      willChange: 'transform',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      transform: 'translateZ(0)'
    })

    this.boundAnimationPlay = this.animationPlay.bind(this)
    this.boundAnimationReverse = this.animationReverse.bind(this)
    this.element.addEventListener('mouseenter', this.boundAnimationPlay)
    this.element.addEventListener('mouseleave', this.boundAnimationReverse)

    this.timeline = gsap.timeline({
      paused: true,
      defaults: {
        duration: 0.2,
        ease: 'power2.inOut',
        force3D: true
      }
    })

    this.timeline
      .to(this.imageTarget, {
        scale: 1.1,
      }, 0)
      .to([this.mutedTarget, this.buttonTarget, this.descriptionTarget], {
        opacity: 1,
      }, 0)
      .to(this.logoTarget, {
        y: -40,
      }, 0)
      .to(this.descriptionTarget, {
        y: 0,
      }, 0)
  }

  animationPlay() {
    if (this.timeline) {
      this.timeline.play()
    }
  }

  animationReverse() {
    if (this.timeline) {
      this.timeline.reverse()
    }
  }
}
