import { Controller } from 'stimulus';
import gsap from 'gsap';

/**
 * This controller is used when infinite scroll is needed in a non-table environment.
 * An example use case would be in our <%= overflow_x_container %> that may have cards or other elements.
 * <%= overflow_x_container %>
 *  <%= tag.div id: "market_cards",
 *             class: 'flex items-center gap-1',
 *             data: {
 *               controller: 'infinite-scroll',
 *               infinite_scroll_next_page_value: content_page.next_param,
 *               infinite_scroll_fetch_url_value: market_pagination_index_url(type: type) } do %>
 *   <% if content_page.records.any? %>
 *     <% content_page.records.each do |offer| %>
          <%= card %>
 *     <% end %>
 *     <% unless content_page.last? %>
 *       <div class="w-full h-[5.5rem] flex items-center justify-center" data-infinite-scroll-target="pagination">
 *         <%= svg_tag('animations/spinner.svg', class: 'w-8 h-8 text-gray-300 opacity-50') %>
 *       </div>
 *     <% end %>
 *   <% else %>
 *     <div class="w-full h-64 flex items-center justify-center">
 *       <p class="text-gray-300 font-medium">No cards found</p>
 *     </div>
 *   <% end %>
 *  <% end %>
 * <% end %>
 * @property {string} animationValue
 * @property {number} nextPageValue
 * @property {boolean} isLoading
 * @property {IntersectionObserver} observer
 * @property {Element} paginationTarget
 * @property {boolean} hasPaginationTarget
 */

export default class extends Controller {
  static targets = ['pagination'];

  static values = {
    animation: { type: String, default: 'fadeIn' },
    nextPage: { type: Number, default: 2 },
    fetchUrl: { type: String, default: '' }
  }

  isLoading = false;
  observer = null;
  boundHandleTurboStream = null;

  initialize() {
    this.boundHandleTurboStream = this.handleTurboStream.bind(this);
  }

  connect() {
    if(!this.element.id) {
      console.error('infinite_scroll_controller: Element must have an ID');
      return;
    }

    // Clean up any existing observer before setting up a new one
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    document.addEventListener(
      'turbo:before-stream-render',
      this.boundHandleTurboStream
    );

    // Initialize intersection observer for infinite scroll
    this.setupInfiniteScroll();
  }

  disconnect() {
    document.removeEventListener(
      'turbo:before-stream-render',
      this.boundHandleTurboStream
    );

    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }

    this.isLoading = false;
  }

  handleTurboStream(event) {
    const action = event.target.getAttribute('action');
    const elementId = event.target.getAttribute('target');

    if (action === 'append' && elementId === this.element.id) {
      event.preventDefault();

      if (this.hasPaginationTarget) {
        this.observer?.disconnect();
        this.paginationTarget.remove();
      }

      const template = event.target.firstElementChild;
      if (!template) return;

      const newContent = template.content.cloneNode(true);
      this.element.appendChild(newContent);

      if (this.hasPaginationTarget) {
        this.observer?.observe(this.paginationTarget);
      }
    }
  }

  setupInfiniteScroll() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.isLoading) {
            this.loadNextPage();
          }
        });
      },
      {
        root: null,
        rootMargin: '100px',
        threshold: 0.1,
      }
    );

    // Observe pagination target if it exists
    if (this.hasPaginationTarget) {
      this.observer.observe(this.paginationTarget);
    }
  }

  async loadNextPage() {
    if (this.isLoading) return;

    this.isLoading = true;

    const url = new URL(this.fetchUrlValue);
    url.searchParams.set('page', this.nextPageValue.toString());

    try {
      const response = await fetch(url.toString(), {
        headers: {
          Accept: 'text/vnd.turbo-stream.html',
        },
      });

      const html = await response.text();
      this.nextPageValue += 1;
      Turbo.renderStreamMessage(html);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      this.isLoading = false;
    }
  }
}
