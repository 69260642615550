import { Controller } from 'stimulus';
import * as Turbo from '@hotwired/turbo';

const isMobile = window.innerWidth < 768;

export default class extends Controller {
  static targets = ['modalLink', 'tableRow'];

  goToLink(event) {
    if (this.otherLinkInsideElementWasClicked(event)) {
      return;
    }

    this.openURL(
      event.currentTarget.dataset.href,
      !!event.currentTarget.dataset.turbo,
      event.ctrlKey || event.metaKey
    );
  }

  goToModalLink(event) {
    if (this.otherLinkInsideElementWasClicked(event)) {
      return;
    }

    this.modalLinkTarget.click();
  }

  async openURL(url, turbo = false, newTab = false) {
    if (newTab) {
      window.open(url, '_blank');
    } else if (turbo) {
      try {
        const response = await fetch(url.toString(), {
          headers: {
            Accept: 'text/vnd.turbo-stream.html',
          },
        });

        const html = await response.text();
        Turbo.renderStreamMessage(html);
      } catch (error) {
        console.error('Error:', error);
      }
    } else {
      window.location = url;
    }
  }

  otherLinkInsideElementWasClicked(event) {
    if (
      event.target.type === 'submit' ||
      event.target.type === 'checkbox' ||
      event.target.closest("[data-links-noclick='true']")
    ) {
      return true;
    } else if (event.target.href !== undefined) {
      return true;
    } else {
      return false;
    }
  }
}
