import { Controller } from 'stimulus'

export default class AddonFormController extends Controller {
  static outlets = ['forms--new-text-field']

  setAddOnValue(event) {
    const select = event.target
    const maxAmount = select.options[select.selectedIndex].dataset.maxAmount
    if (maxAmount) this.formsNewTextFieldOutlet.setAddOnValue(maxAmount)
  }
}
