import { Controller } from "stimulus"

/*
 * The order book is quite complex instead of relying on Turbo appends/prepends we simply ask the
 * user to fetch updated content themselves when we make a change to the order_book.
 */

export default class extends Controller {
  connect() {
    this.handleStreamRender = this.handleStreamRender.bind(this)
    document.addEventListener("turbo:before-stream-render", this.handleStreamRender)
  }

  disconnect() {
    document.removeEventListener("turbo:before-stream-render", this.handleStreamRender)
  }

  handleStreamRender(event) {
    const action = event.target.getAttribute("action")
    if (action === "order_book_update") {
      event.preventDefault()
      this.refreshOrderBook()
    }
  }

  refreshOrderBook() {
    // Get the base URL without any search parameters
    const url = new URL(window.location.href.split('?')[0])
    url.searchParams.set('tab', 'trading')
    url.searchParams.set('refresh_order_book', 'true')

    // Fetch the updated order book content
    fetch(url.toString(), {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      }
    })
      .then(response => response.text())
      .then(html => {
        Turbo.renderStreamMessage(html)
      })
  }
}
