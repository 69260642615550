import { Controller } from "stimulus"
import gsap from "gsap";

export default class extends Controller {
  static targets = ["background", "item"]

  connect() {
    this.initializeBackground()

    // Add click handlers
    this.itemTargets.forEach(item => {
      item.addEventListener('click', (e) => {
        this.setActiveItem(item)
      })
    })

    // Add resize handler with debounce
    this.resizeHandler = this.debounce(() => {
      const activeItem = this.itemTargets.find(item => item.dataset.active === "true")
      if (activeItem) {
        this.moveBackground(activeItem, false) // false means no animation
      }
    }, 250) // 250ms debounce

    window.addEventListener('resize', this.resizeHandler)
  }

  disconnect() {
    // Clean up resize listener when controller is disconnected
    window.removeEventListener('resize', this.resizeHandler)
  }

  initializeBackground() {
    const activeItem = this.itemTargets.find(item => item.dataset.active === "true")
    if (activeItem) {
      // Position the background without animation
      this.moveBackground(activeItem, false)

      // Remove hidden class and fade in
      this.backgroundTarget.classList.remove('hidden')
      gsap.to(this.backgroundTarget, {
        opacity: 1,
        duration: 0.2,
        ease: "power1.inOut"
      })
    }
  }

  setActiveItem(clickedItem) {
    // Update active states
    this.itemTargets.forEach(item => {
      item.dataset.active = "false"
    })

    clickedItem.dataset.active = "true"

    // Animate background with color change
    this.moveBackground(clickedItem, true)
  }

  moveBackground(targetItem, animate = true) {
    const rect = targetItem.getBoundingClientRect()
    const navRect = this.element.getBoundingClientRect()
    const leftOffset = rect.left - navRect.left
    const newColor = targetItem.dataset.color || '#2D4247'

    if (animate) {
      gsap.to(this.backgroundTarget, {
        x: leftOffset,
        width: rect.width,
        backgroundColor: newColor,
        duration: 0.1,
        ease: "power2.out"
      })
    } else {
      gsap.set(this.backgroundTarget, {
        x: leftOffset,
        width: rect.width,
        backgroundColor: newColor
      })
    }
  }

  // Utility function to debounce resize events
  debounce(func, wait) {
    let timeout
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout)
        func(...args)
      }
      clearTimeout(timeout)
      timeout = setTimeout(later, wait)
    }
  }
}
