import { Controller } from 'stimulus';
import { useMatchMedia, useDispatch, useResize } from 'stimulus-use';
let bodyWidth;

export default class extends Controller {
  static targets = ['row'];
  static values = {
    userId: Number,
    currentUserId: Number,
    expansionRowId: String
  };

  connect() {
    useDispatch(this);
    useMatchMedia(this, {
      mediaQueries: { small: '(min-width: 320px) and (max-width: 769px)' },
    });
    useResize(this, { element: document.body });

    this.setUserSpecificContent();
  }

  setUserSpecificContent() {
    if (this.userIdValue === this.contentController.userIdValue) {
      this.element
        .querySelectorAll(`.only-user-${this.userIdValue}`)
        .forEach((element) => {
          element.classList.remove('hidden', '!hidden');
        });
    } else {
      this.element
        .querySelectorAll(`.not-user-${this.userIdValue}`)
        .forEach((element) => {
          element.classList.remove('hidden', '!hidden');
        });
    }
    // Verify the right html elements are displayed
    setTimeout(() => {
      this.rowTarget.classList.remove('!bg-blue-300');
      this.rowTarget.classList.remove('!text-white');
    }, 3000);
  }

  click(event) {
    this.dispatch('click', { event });
  }

  resize({ width }) {
    bodyWidth = width;
  }

  toggleRow(event) {
    if (bodyWidth > 768) {
      if (event.currentTarget.dataset.href) {
        this.click(event);
      }
    } else if (
      this.expansionRowIdValue &&
      this.rowTarget.nextElementSibling?.id === this.expansionRowIdValue
    ) {
      this.rowTarget
        .querySelector('.expander-icon')
        .classList.toggle('rotate-icon');
      this.rowTarget.classList.toggle('expanded-row');
      this.rowTarget.nextElementSibling.classList.toggle('hide-row');
      setTimeout(() => {
        this.rowTarget.nextElementSibling
          .querySelector('.mobile-extra-content__actions')
          .classList.toggle('collapsed');
      }, 100);
    }
  }

  notSmall({ name, media, matches, event }) {
    if (
      this.expansionRowIdValue &&
      this.rowTarget.nextElementSibling?.id &&
      this.rowTarget.nextElementSibling.id === this.expansionRowIdValue
    ) {
      this.rowTarget
        .querySelector('.expander-icon')
        .classList.remove('rotate-icon');
      this.rowTarget.classList.remove('expanded-row');
      this.rowTarget.nextElementSibling.classList.add('hide-row');
      this.rowTarget.nextElementSibling
        .querySelector('.mobile-extra-content__actions')
        .classList.add('collapsed');
    }
  }

  get contentController() {
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-controller~=tables--content]'),
      'tables--content'
    );
  }
}
