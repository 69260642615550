import { Controller } from "stimulus";
import { gsap } from "gsap"; // Make sure GSAP is available

export default class extends Controller {
  static targets = ["categorySelect", "searchInput", "iconContainer", "loadMoreTrigger"];

  connect() {
    this.batchSize = 100; // Number of icons to load per batch
    this.renderedIcons = 0; // Count of currently rendered icons
    this.icons = []; // Initialize icons array

    // Fetch icon data asynchronously from Rails route
    this.fetchIconData();
  }

  async fetchIconData() {
    try {
      // Fetch icon data from the new route
      const response = await fetch('/partner/platforms/icons/icon_map');
      this.icons = await response.json();

      // Populate the category select dropdown
      this.populateCategorySelect();

      // Render the first batch of icons after fetching data
      this.loadMoreIcons();

      // Initialize observer after rendering the first batch
      this.initializeObserver();
    } catch (error) {
      console.error('Error fetching icon data:', error);
    }
  }

  populateCategorySelect() {
    // Get unique categories from icons
    const categories = this.icons.reduce((acc, icon) => {
      if (!acc[icon.directory]) {
        acc[icon.directory] = 1;
      } else {
        acc[icon.directory]++;
      }
      return acc;
    }, {});

    // Create "All" option
    const allOption = document.createElement('option');
    allOption.value = "all";
    allOption.textContent = `All (${this.icons.length})`;
    this.categorySelectTarget.appendChild(allOption);

    // Create options for each category
    for (const [category, count] of Object.entries(categories)) {
      const option = document.createElement('option');
      option.value = category;
      option.textContent = `${category.replace(/_/g, ' ').toUpperCase()} (${count})`;
      this.categorySelectTarget.appendChild(option);
    }
  }

  initializeObserver() {
    // Set up IntersectionObserver to trigger loading more icons when scrolled into view
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadMoreIcons();
        }
      });
    });

    if (this.hasLoadMoreTriggerTarget) {
      this.observer.observe(this.loadMoreTriggerTarget);
    }
  }

  loadMoreIcons() {
    // Get the filtered icons and load the next batch
    const filteredIcons = this.getFilteredIcons();
    const iconsToRender = filteredIcons.slice(
      this.renderedIcons,
      this.renderedIcons + this.batchSize
    );

    // Render the next batch
    iconsToRender.forEach(icon => {
      this.renderIcon(icon);
    });

    this.renderedIcons += iconsToRender.length;

    // Disconnect the observer if all icons are rendered
    if (this.renderedIcons >= filteredIcons.length) {
      this.observer.disconnect();
    }
  }

  getFilteredIcons() {
    // Get the selected category and search input value
    const selectedCategory = this.categorySelectTarget.value.toLowerCase();
    const searchQuery = this.searchInputTarget.value.toLowerCase();

    // Filter icons based on category and search query
    return this.icons.filter(icon => {
      const matchesCategory = selectedCategory === "all" || icon.directory === selectedCategory;
      const matchesSearch = icon.name.toLowerCase().includes(searchQuery);

      return matchesCategory && matchesSearch;
    });
  }

  renderIcon(icon) {
    // Find or create the directory container
    let directoryContainer = this.iconContainerTarget.querySelector(`[data-category="${icon.directory}"]`);

    if (!directoryContainer) {
      directoryContainer = document.createElement('div');
      directoryContainer.className = "flex flex-col category";
      directoryContainer.dataset.category = icon.directory;

      // Add directory header
      const header = document.createElement('h2');
      header.className = "text-lg font-bold mb-4";
      header.textContent = icon.directory.replace(/_/g, ' ').toUpperCase();
      directoryContainer.appendChild(header);

      // Create grid container for icons
      const gridContainer = document.createElement('div');
      gridContainer.className = "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-6 items-center gap-4";
      directoryContainer.appendChild(gridContainer);

      this.iconContainerTarget.appendChild(directoryContainer);
    }

    const gridContainer = directoryContainer.querySelector('.grid');

    // Create and append the icon div
    const iconDiv = document.createElement('div');
    iconDiv.className = "flex flex-col transition-colors bg-card-background cursor-pointer hover:bg-card-background/70 rounded-md items-center p-2 icon";
    iconDiv.dataset.iconName = icon.name;
    iconDiv.dataset.action = "click->icon-filter#copyToClipboard";

    // Add the pre-rendered SVG HTML
    const svgWrapper = document.createElement('div');
    svgWrapper.className = "w-12 h-12 flex items-center justify-center";
    svgWrapper.innerHTML = icon.html;

    const span = document.createElement('span');
    span.className = "text-xs overflow-hidden text-center text-ellipsis";
    span.style.maxWidth = "12rem";
    span.textContent = icon.name;

    iconDiv.appendChild(svgWrapper);
    iconDiv.appendChild(span);
    gridContainer.appendChild(iconDiv);
  }

  filterByCategory() {
    this.resetRenderedIcons();
    this.loadMoreIcons(); // Load the first batch of filtered icons
  }

  filterBySearch() {
    this.resetRenderedIcons();
    this.loadMoreIcons(); // Load the first batch of filtered icons
  }

  resetRenderedIcons() {
    this.renderedIcons = 0;
    this.iconContainerTarget.innerHTML = ''; // Clear current icons
    this.observer.observe(this.loadMoreTriggerTarget); // Reconnect observer
  }

  // Handle icon click to copy the helper tag (same as before)
  copyToClipboard(event) {
    const iconElement = event.currentTarget;
    const iconName = iconElement.getAttribute('data-icon-name'); // Remove .svg from name
    const categoryName = iconElement.closest('.category').getAttribute('data-category');

    // The tag to be copied to clipboard
    const svgTagString = `${categoryName}/${iconName}`;

    // Check if clipboard API is supported
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard.writeText(svgTagString).then(() => {
        this.showCopiedAnimation(iconElement, iconName);
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      this.fallbackCopyTextToClipboard(svgTagString);
      this.showCopiedAnimation(iconElement, iconName);
    }
  }

  // Fallback method to copy text if Clipboard API is not available
  fallbackCopyTextToClipboard(text) {
    const editableDiv = document.createElement("div");
    editableDiv.contentEditable = "true";
    editableDiv.style.position = "absolute";
    editableDiv.style.opacity = "0";
    editableDiv.style.pointerEvents = "none";
    editableDiv.textContent = text;
    document.body.appendChild(editableDiv);

    const range = document.createRange();
    range.selectNodeContents(editableDiv);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    try {
      document.execCommand('copy');
    } catch (err) {
      console.error('Fallback: Unable to copy', err);
    }

    selection.removeAllRanges();
    document.body.removeChild(editableDiv);
  }

  showCopiedAnimation(iconElement, iconName) {
    gsap.to(iconElement, {
      backgroundColor: "var(--sky-medium-hex)",
      color: "white",
      duration: 0.2,
      onComplete: () => {
        iconElement.querySelector('span').innerText = 'Copied';
        setTimeout(() => {
          gsap.to(iconElement, {
            backgroundColor: "",
            color: "",
            duration: 0.2,
            onComplete: () => {
              iconElement.querySelector('span').innerText = iconName;
            }
          });
        }, 1000);
      }
    });
  }
}
