import { Controller } from 'stimulus';

export default class extends Controller {
  static values = {
    maxRetries: { type: Number, default: 5 },
    retryDelay: { type: Number, default: 100 },
    enableAttachments: { type: Boolean, default: true }
  }

  connect() {
    this.retryCount = 0;
    this.initialized = false;
    this.initializeTrix();

    this.boundInitializer = () => this.initializeTrix();
    document.addEventListener('trix-initialize', this.boundInitializer);
  }

  disconnect() {
    document.removeEventListener('trix-initialize', this.boundInitializer);
  }

  initializeTrix() {
    if (this.initialized) return;

    this.toolbar = document.getElementById(this.element.getAttribute('toolbar'));

    if (this.toolbar) {
      this.setupToolbar();
      this.initialized = true;
      document.removeEventListener('trix-initialize', this.boundInitializer);
    } else if (this.retryCount < this.maxRetriesValue) {
      this.retryCount++;
      console.log(`Retry attempt ${this.retryCount}`);
      setTimeout(() => this.initializeTrix(), this.retryDelayValue);
    } else {
      console.error('Failed to initialize Trix toolbar, max retries exceeded, check if your trix controller is connected to a rich_text_area.');
    }
  }

  setupToolbar() {
    this.handleFileAcceptance();
    if (!this.enableAttachmentsValue) {
      this.disableFileTools();
    } else {
      this.addEmbedButtonToToolbar();
      this.listenForEmbedButtonClick();
    }
  }

  handleFileAcceptance() {
    window.addEventListener('trix-file-accept', (event) => {
      const file = event.file;

      if (!this.acceptedTypes.includes(file.type)) {
        event.preventDefault();
        alert('Only jpeg, png, or gif images are supported');
        return;
      }

      if (file.size > this.maxFileSize) {
        event.preventDefault();
        alert('Files must be smaller than 1MB');
      }
    });
  }

  get acceptedTypes() {
    return [
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/gif',
    ];
  }

  get maxFileSize() {
    return 1024 * 1024; // 1MB
  }

  disableFileTools() {
    this.removeFileToolsGroup();
    document.addEventListener("trix-attachment-add", function(event) {
      if (!event.attachment.file) {
          event.attachment.remove()
      }
    });
    // No files, ever
    document.addEventListener("trix-file-accept", function(event) {
      event.preventDefault();
    });
  }

  removeFileToolsGroup() {
    const fileToolsGroup = this.toolbar.querySelector(
      '.trix-button-group--file-tools'
    );
    if (!fileToolsGroup) return;

    fileToolsGroup.remove();
  }

  addEmbedButtonToToolbar() {
    const fileToolsGroup = this.toolbar.querySelector(
      '.trix-button-group--file-tools'
    );
    if (!fileToolsGroup) return;

    // Check if the embed button already exists
    const existingButton = fileToolsGroup.querySelector(
      '[data-trix-attribute="video-embed"]'
    );
    if (existingButton) return;

    const embedButton = document.createElement('button');
    embedButton.type = 'button';
    embedButton.className =
      'trix-button trix-button--icon trix-button--icon-embed';
    embedButton.setAttribute('data-trix-attribute', 'video-embed');
    embedButton.title = 'Embed YT Video';
    embedButton.tabIndex = '-1';

    const attachFilesButton = fileToolsGroup.querySelector(
      '.trix-button--icon-attach'
    );
    attachFilesButton
      ? attachFilesButton.after(embedButton)
      : fileToolsGroup.appendChild(embedButton);
  }

  listenForEmbedButtonClick() {
    this.toolbar.addEventListener('click', (event) => {
      if (
        event.target.type !== 'button' ||
        event.target.getAttribute('data-trix-attribute') !== 'video-embed'
      )
        return;
      const url = prompt('Enter YouTube video URL:');
      if (this.isValidYouTubeURL(url)) {
        this.insertYouTubeThumbnail(this.getYouTubeID(url));
      } else {
        alert('Invalid YouTube URL!');
      }
    });
  }

  insertYouTubeThumbnail(videoId) {
    const content = this.generateThumbnailContent(videoId);
    this.element.editor.insertHTML(content);
  }

  generateThumbnailContent(videoId) {
    return `<img src="https://img.youtube.com/vi/${videoId}/maxresdefault.jpg"/>`;
  }

  isValidYouTubeURL(url) {
    const pattern =
      /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)[a-zA-Z0-9_-]{11}(\S*)?$/;
    return pattern.test(url);
  }

  getYouTubeID(url) {
    let video_id = url.split('v=')[1];
    const ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition !== -1)
      video_id = video_id.substring(0, ampersandPosition);
    return video_id;
  }
}
