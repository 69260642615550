import { Controller } from 'stimulus';

export default class FormComponentController extends Controller {
  static targets = [
    'groupSelect',
    'categorySelect',
    'amountInput',
    'deductAllButtonWrapper',
    'deductAllButton',
  ];

  static values = { groupsAndAmounts: Object, maxAmount: Number, forceReadonly: Boolean };
  static outlets = ['forms--new-text-field'];

  connect() {
    this.configureAmountInput();
  }

  configureAmountInputForAddition() {
    this.categorySelectTarget.value = 'addition'; // Reset category to 'addition' when group changes
    this.configureAmountInput();
  }

  configureAmountInput() {
    const selectedCategory = this.categorySelectTarget.value;
    this.deductAllButtonTarget.checked = false;
    this.amountInputTarget.value = '';
    if (selectedCategory === 'addition') {
      this.amountInputTarget.placeholder = 'Enter tokens to add';
      this.amountInputTarget.max = '';
      if (!this.forceReadonlyValue) this.toggleReadonlyWithValidation(false);
      this.deductAllButtonWrapperTarget.classList.add('hidden');
    } else {
      const selectedGroup = this.groupSelectTarget.value;
      // Including 'deduction'
      this.amountInputTarget.placeholder = 'Enter amount to deduct';
      this.amountInputTarget.max =
        this.groupsAndAmountsValue[selectedGroup]?.toString() || '';
      this.deductAllButtonWrapperTarget.classList.remove('hidden');
    }
  }

  setDeductionAmountAndInputState(event) {
    const checkbox = event.target;
    if (checkbox.checked) {
      const selectedGroup = this.groupSelectTarget.value;
      this.formsNewTextFieldOutlet.handleFocusInput();
      this.amountInputTarget.value = this.groupsAndAmountsValue[selectedGroup]?.toString() || '';
      this.toggleReadonlyWithValidation(true);
    } else if (!this.forceReadonlyValue) {
      this.toggleReadonlyWithValidation(false);
    }
  }

  toggleReadonlyWithValidation(condition) {
    // When true, we prevent the user from typing anything in the input but we still want to trigger HTML validations
    if (condition) {
      this.amountInputTarget.addEventListener('keydown', this.preventKeyPress);
      this.amountInputTarget.addEventListener('keypress', this.preventKeyPress);
    } else {
      this.amountInputTarget.removeEventListener('keydown', this.preventKeyPress);
      this.amountInputTarget.removeEventListener('keypress', this.preventKeyPress);
    }
  }

  amountInputTargetDisconnected(target) {
    target.removeEventListener('keydown', this.preventKeyPress);
    target.removeEventListener('keypress', this.preventKeyPress);
  }

  preventKeyPress = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  }
}
