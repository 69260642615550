import { Controller } from 'stimulus';
import { delay } from '@/helpers/timing_helpers';

export default class extends Controller {
  static targets = ['searchInputContainer', 'searchInput'];
  static outlets = ['tables--content']

  static values = {
    lastSearchIdentifier: String,
    lastSearchTerm: String,
    tableGuid: String,
  };

  SEARCH_COOLDOWN_TIME = 250; // ms
  MINIMUM_CHARACTER_LIMIT = 3;

  async handleNewSearchTerm(event) {
    let searchTerm = event.target.value;
    if (
      this.searchTermIsCorrectLength(searchTerm) &&
      this.searchTermWasChanged(searchTerm)
    ) {
      this.search(searchTerm);
    } else if (
      this.searchTermWasChanged(searchTerm) &&
      !!this.lastSearchTermValue
    ) {
      this.search(searchTerm);
      if(this.hasTablesContentOutlet) {
        this.tablesContentOutlet.update({ searchTerm: '' });
      } else {
        this.contentController.update({ searchTerm: '' });
      }
    }
  }

  searchTermIsCorrectLength(searchTerm) {
    return searchTerm.length >= this.MINIMUM_CHARACTER_LIMIT;
  }

  searchTermWasChanged(searchTerm) {
    return searchTerm !== this.lastSearchTermValue;
  }

  async search(searchTerm) {
    if (await this.searchWasCancelled()) return;

    this.lastSearchTermValue = searchTerm;

    if (searchTerm === '' && !!this.lastSearchTermValue) return;
    else {
      if(this.hasTablesContentOutlet) {
        this.tablesContentOutlet.update({ searchTerm });
      } else {
        this.contentController.update({ searchTerm });
      }
    }
  }

  async searchWasCancelled() {
    let currentSearchIdentifier = Math.random().toString().substr(2, 14);
    this.lastSearchIdentifierValue = currentSearchIdentifier;
    await delay(this.SEARCH_COOLDOWN_TIME);
    return this.lastSearchIdentifierValue !== currentSearchIdentifier;
  }

  containerClick() {
    if(this.hasSearchInputTarget) {
      this.searchInputTarget.focus();
    }
  }

  get contentController() {
    let querySelector = '[data-controller~=tables--content]';
    if (this.tableGuidValue !== null) {
      querySelector += `[data-table-guid="${this.tableGuidValue}"]`;
    }
    return this.application.getControllerForElementAndIdentifier(
      document.querySelector(querySelector),
      'tables--content'
    );
  }
}
