import { Controller } from 'stimulus';
import { hideElement, showElement } from '@/helpers/animations_helpers';

export default class NewTextFieldController extends Controller {
  static targets = [
    'label',
    'inputEl',
    'errorIcon',
    'trailingIcon',
    'leadingIcon',
    'addOnValue',
    'errorText',
  ];

  static values = { addOnValue: String };

  static inputClasses = [
    'bg-white/5',
    'text-white',
    'ring-white/10',
    'placeholder:text-gray-300',
    'focus:ring-sky-medium',
  ];

  static errorClasses = ['text-red-500', 'ring-red-500', 'bg-white/10'];

  handleFocusInput() {
    this.labelTarget.childNodes.forEach((node) => {
      if (node instanceof HTMLInputElement) {
        node.focus();
      }
    });
  }

  addError(text) {
    this.addErrorClasses();
    this.errorTextTarget.textContent = text;
    showElement(this.errorTextTarget);
  }

  removeError() {
    this.removeErrorClasses();
    hideElement(this.errorTextTarget);
  }

  handleValidateInput() {
    const pattern = this.inputElTarget.getAttribute('pattern');
    const notEmpty = this.inputElTarget.value.length > 0;

    if (pattern && notEmpty) {
      const regex = new RegExp(pattern);
      if (!regex.test(this.inputElTarget.value)) {
        this.addErrorClasses();
      } else {
        this.removeErrorClasses();
      }
    } else if (notEmpty) {
      this.removeErrorClasses();
    } else {
      if (this.inputElTarget.hasAttribute('required')) {
        this.addErrorClasses();
      } else {
        this.removeErrorClasses();
      }
    }
  }

  addErrorClasses() {
    this.inputElTarget.classList.add(...NewTextFieldController.errorClasses);
    this.inputElTarget.classList.remove(...NewTextFieldController.inputClasses);

    // We hide the trailing icon in favour of the error
    // icon when the input does not match the pattern
    if (this.hasTrailingIconTarget) {
      this.trailingIconTarget.classList.add('hidden');
    } else {
      this.inputElTarget.classList.add('pr-10');
    }

    if (this.hasErrorIconTarget) {
      this.errorIconTarget.classList.remove('hidden');
      this.errorIconTarget.classList.add('flex');
    }
  }

  removeErrorClasses() {
    this.inputElTarget.classList.remove(...NewTextFieldController.errorClasses);
    this.inputElTarget.classList.add(...NewTextFieldController.inputClasses);

    // Renables the dynamic icon
    if (this.hasTrailingIconTarget) {
      this.trailingIconTarget.classList.remove('hidden');
    } else {
      this.inputElTarget.classList.remove('pr-10');
    }

    if (this.hasErrorIconTarget) {
      this.errorIconTarget.classList.add('hidden');
    }
  }

  fillWithAddOnValue(event) {
    event.preventDefault();
    this.removeError();
    this.inputElTarget.value = this.addOnValueValue;
    this.inputElTarget.focus();
  }

  setAddOnValue(value) {
    this.addOnValueValue = value;
  }

  addOnValueValueChanged() {
    this.inputElTarget.max = this.addOnValueValue;
  }

  showAddOn() {
    this.addOnValueTarget.classList.remove('hidden');
  }

  hideAddOn() {
    this.addOnValueTarget.classList.add('hidden');
  }
}
