import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fieldsWrapper", "checkbox"]

  connect() {
    if (!this.checkboxTarget.disabled) {
      this.toggleFields()
    }
  }

  toggleFields() {
    this.fieldsWrapperTarget.classList.toggle('hidden', !this.checkboxTarget.checked)
  }
} 