import { Controller } from 'stimulus'
import { useClickOutside } from 'stimulus-use'
import { gsap } from 'gsap'

export default class extends Controller {
  static targets = ['menu', 'button', 'icon']
  static values = {
    hoisted: { type: Boolean, default: false }
  }
  originalParent = null;
  clonedMenu = null;

  connect() {
    this.dropdownOpen = false;
    this.lastActiveElement = null;

    useClickOutside(this, this.close);

    if (this.hoistedValue) {
      this.originalParent = this.menuTarget.parentElement;

      // Clone the menu and append it to body
      this.clonedMenu = this.menuTarget.cloneNode(true);
      this.clonedMenu.style.position = 'fixed';
      this.clonedMenu.style.zIndex = '1000000';

      // Attach to main-content to make sure correct controllers get added (actions, trade_modal, etc)
      const mainContentElement = document.getElementById('main-content');
      mainContentElement ? mainContentElement.appendChild(this.clonedMenu) : document.body.appendChild(this.clonedMenu);

      // Create resize observer
      this.resizeObserver = new ResizeObserver(() => {
        if (this.dropdownOpen) {
          this.repositionMenu();
        }
      });

      // Observe both the button and the body for any size changes
      this.resizeObserver.observe(this.buttonTarget);
      this.resizeObserver.observe(document.body);
    }
  }

  disconnect() {
    // Clean up resize observer
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }

    // Clean up hoisted menu
    if (this.clonedMenu) {
      if (this.clonedMenu.parentElement) {
        this.clonedMenu.parentElement.removeChild(this.clonedMenu);
      }
      this.clonedMenu = null;
    }

    this.originalParent = null;
  }

  toggle (e) {
    e.stopPropagation();
    this.dropdownOpen ? this.close(e) : this.open(e)
  }

  open(e) {
    e.preventDefault()

    if(this.hasIconTarget) {
      this.iconTarget.classList.add('rotate-180')
    }

    this.lastActiveElement = document.activeElement;
    this.buttonTarget.setAttribute('aria-expanded', 'true')
    this.dropdownOpen = true

    if (this.hoistedValue) {
      this.repositionMenu()
      // Animate the cloned menu in
      gsap.fromTo(this.clonedMenu, { opacity: 0, scale: 0.95 }, { opacity: 1, scale: 1, duration: 0.2, onStart: () => {
          if (this.clonedMenu) {
            this.clonedMenu.classList.remove('hidden');
          }
        }});
    } else {
      // Animate the menuTarget in
      gsap.fromTo(this.menuTarget, { opacity: 0, scale: 0.95 }, { opacity: 1, scale: 1, duration: 0.2, onStart: () => {
          this.menuTarget.classList.remove('hidden');
        }});
    }
  }

  close(e) {
    e.preventDefault()

    if(this.hasIconTarget) {
      this.iconTarget.classList.remove('rotate-180')
    }

    this.buttonTarget.setAttribute('aria-expanded', 'false')
    this.dropdownOpen = false

    if (this.hoistedValue) {
      // Animate the cloned menu out
      gsap.to(this.clonedMenu, { opacity: 0, scale: 0.95, duration: 0.2, onComplete: () => {
          if (this.clonedMenu) {
            this.clonedMenu.classList.add('hidden');
            this.clonedMenu.style.top = 0;
            this.clonedMenu.style.left = 0;
          }
        }});
    } else {
      // Animate the menuTarget out
      gsap.to(this.menuTarget, { opacity: 0, scale: 0.95, duration: 0.2, onComplete: () => {
          this.menuTarget.classList.add('hidden');
        }});
    }
  }

  repositionMenu() {
    if (!this.dropdownOpen || !this.clonedMenu) {
      return;
    }

    // Temporarily show the menu to get its dimensions
    this.clonedMenu.style.visibility = 'hidden';
    this.clonedMenu.classList.remove('hidden');

    const buttonRect = this.buttonTarget.getBoundingClientRect();
    const menuRect = this.clonedMenu.getBoundingClientRect();

    // Since we're using position: fixed, we only need the viewport coordinates
    // No need to add scrollY since fixed positioning is relative to the viewport
    const top = buttonRect.bottom;
    const left = buttonRect.right - menuRect.width;

    // Reset visibility
    this.clonedMenu.style.visibility = '';
    this.clonedMenu.classList.add('hidden');

    // Apply the calculated position
    this.clonedMenu.style.top = `${top}px`;
    this.clonedMenu.style.left = `${left}px`;
  }
}
