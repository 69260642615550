import { Controller } from 'stimulus';
import { toggleVisibility } from '@/helpers/animations_helpers';

export default class extends Controller {
  static targets = [
    'capSettings',
    'nameInput',
    'tierSelect',
    'tierGroupDivSelect',
    'addressList',
    'whitelistInput',
    'capSettingsInput',
    'radioInput',
    'radioLabel',
    'radioTier',
    'radioWhitelist',
    'radioPrivate',
    'radioUnrestricted',
    'submitButton',
    'errorMessageDisplay',
    'errorMessageContent',
    'errorExplanation',
    'tierStacking',
    'tierGroupSelect',
  ];

  static values = {
    tierGroups: Array,
  };

  sortedLines;

  connect() {
    this.handleChecked();
    this.validateWhitelist();
    this.tierSelect();
  }

  toggleInputs(event) {
    if (!this.addressListTarget.classList.contains('hidden')) {
      toggleVisibility(this.addressListTarget);
    }
    this.whitelistInputTarget.setAttribute('disabled', true);
    this.tierGroupSelectTarget.setAttribute('disabled', true);

    this.nameInputTarget.classList.add('hidden');
    this.tierSelectTarget.classList.add('hidden');
    this.tierGroupDivSelectTarget.classList.add('hidden');
    this.tierStackingTarget.classList.add('hidden');

    if (event.currentTarget.value == 'legacy_tier_based') {
      this.tierSelectTarget.classList.remove('hidden');
      this.nameInputTarget.setAttribute('disabled', true);
      this.tierSelectTarget.removeAttribute('disabled');
    } else if (event.currentTarget.value == 'tier_based') {
      this.tierStackingTarget.classList.remove('hidden');
      this.tierGroupDivSelectTarget.classList.remove('hidden');
      this.tierGroupSelectTarget.removeAttribute('disabled');
      this.nameInputTarget.setAttribute('disabled', true);
    } else {
      if (event.currentTarget.value == 'whitelist') {
        if (this.addressListTarget.classList.contains('hidden')) {
          toggleVisibility(this.addressListTarget);
        }
        this.whitelistInputTarget.removeAttribute('disabled');
      }
      this.nameInputTarget.classList.remove('hidden');
      this.tierSelectTarget.setAttribute('disabled', true);
      this.tierGroupDivSelectTarget.setAttribute('disabled', true);
      this.nameInputTarget.removeAttribute('disabled');
    }

    this.handleChecked();
    this.tierSelect();
  }

  toggleCapSettings(event) {
    if (event.currentTarget.checked) {
      toggleVisibility(this.capSettingsTarget);
    } else {
      this.capSettingsInputTarget.value = null;
      toggleVisibility(this.capSettingsTarget);
    }
  }

  handleChecked() {
    this.radioInputTargets.forEach((radio) => {
      if (radio.checked) {
        radio.parentElement.classList.add('n-radio-button--checked');
      } else {
        radio.parentElement.classList.remove('n-radio-button--checked');
      }
    });
  }

  validateWhitelist() {
    if (!this.hasWhitelistInputTarget) {
      return;
    }

    const lines = this.whitelistInputTarget.value.split('\n');
    this.sortedLines = {
      validLines: [],
      invalidAddressLines: [],
      duplicateAddressLines: [],
      invalidMinLines: [],
      invalidMaxLines: [],
      ignorableLines: [],
    };

    lines.forEach((line) => {
      if (!line.includes('0x')) {
        this.sortedLines.ignorableLines.push(line);
        return;
      }

      // Split by comma or space
      const parts = line.split(/[\t ,]+/).map((part) => part.trim());
      if (!this.isValidAddress(parts[0])) {
        this.sortedLines.invalidAddressLines.push(parts);
        return;
      }

      if (this.isDuplicateAddress(parts[0])) {
        this.sortedLines.duplicateAddressLines.push(parts);
        return;
      }

      if (parts.length <= 1) {
        this.sortedLines.validLines.push(parts);
        return;
      }

      if (parts.length >= 2) {
        this.validateMinMaxAmounts(parts);
        return;
      }

      this.sortedLines.invalidMinLines.push(parts);
    });

    this.updateSubmitButton();
  }

  isValidAddress(address) {
    return address.match(/^0x[a-fA-F0-9]{40}$/);
  }

  isDuplicateAddress(address) {
    return this.sortedLines.validLines.some(
      (line) => line[0].toLowerCase() === address.toLowerCase()
    );
  }

  validateMinMaxAmounts(line) {
    const [_address, min, max] = line;
    if (!min) {
      if (max) this.sortedLines.invalidMinLines.push(line);
      else this.sortedLines.validLines.push(line);
      return;
    }

    const minVal = parseFloat(min);
    const maxVal = parseFloat(max);

    if (isNaN(minVal) || minVal <= 0) {
      this.sortedLines.invalidMinLines.push(line);
      return;
    }

    if ((max && isNaN(maxVal)) || (maxVal && maxVal < minVal)) {
      this.sortedLines.invalidMaxLines.push(line);
      return;
    }

    this.sortedLines.validLines.push(line);
  }

  updateSubmitButton() {
    const invalidLinesCount =
      this.whitelistInputTarget.value.split('\n').length -
      this.sortedLines.validLines.length -
      this.sortedLines.ignorableLines.length;
    if (invalidLinesCount <= 0) {
      this.submitButtonTarget.removeAttribute('disabled');
      this.errorMessageDisplayTarget.classList.add('hidden');
    } else {
      this.submitButtonTarget.setAttribute('disabled', true);
      this.errorMessageDisplayTarget.classList.remove('hidden');
      this.errorMessageContentTarget.innerHTML = `${invalidLinesCount} ${
        invalidLinesCount == 1 ? 'issue' : 'issues'
      } found`;
    }
  }

  openErrorExplanation(event) {
    event.preventDefault();

    let errorText = '';
    if (this.sortedLines.invalidAddressLines.length > 0) {
      errorText += `<p><span class="font-bold">Invalid addresses:</span><br>${this.sortedLines.invalidAddressLines
        .map((line) => {
          line[0] = `<span class="text-red-400">${line[0]}</span>`;
          return line;
        })
        .map((line) => line.join(', '))
        .join('<br>')}</p>`;
    }

    if (this.sortedLines.duplicateAddressLines.length > 0) {
      errorText += `<p><span class="font-bold">Duplicate addresses:</span><br>${this.sortedLines.duplicateAddressLines
        .map((line) => {
          line[0] = `<span class="text-red-400">${line[0]}</span>`;
          return line;
        })
        .map((line) => line.join(', '))
        .join('<br>')}</p>`;
    }

    if (this.sortedLines.invalidMinLines.length > 0) {
      errorText += `<p><span class="font-bold">Invalid min investment amount:</span><br>${this.sortedLines.invalidMinLines
        .map((line) => {
          line[1] = `<span class="text-red-400">${line[1]}</span>`;
          return line;
        })
        .map((line) => line.join(', '))
        .join('<br>')}</p>`;
    }

    if (this.sortedLines.invalidMaxLines.length > 0) {
      errorText += `<p><span class="font-bold">Invalid max investment amount:</span><br>${this.sortedLines.invalidMaxLines
        .map((line) => {
          line[2] = `<span class="text-red-400">${line[2]}</span>`;
          return line;
        })
        .map((line) => line.join(', '))
        .join('<br>')}</p>`;
    }

    this.errorExplanationTarget.innerHTML = errorText;
    this.errorExplanationTarget.classList.remove('!hidden');
  }

  closeErrorExplanation() {
    this.errorExplanationTarget.classList.add('!hidden');
  }

  tierSelect() {
    if (!this.hasTierGroupSelectTarget || this.tierGroupSelectTarget.disabled) {
      return;
    }

    const selectedOption =
      this.tierGroupSelectTarget.options[
        this.tierGroupSelectTarget.selectedIndex
      ];
    const tierTokenType = selectedOption.getAttribute('data-token-type');

    if (tierTokenType === 'nft') {
      this.tierStackingTarget.classList.remove('hidden');
    } else {
      this.tierStackingTarget.classList.add('hidden');
    }
  }
}
