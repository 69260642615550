import { Controller } from 'stimulus';
import { estimateTokenPriceValues, numberWithPrecision } from './utils';
import debounce from 'debounce';

export default class extends Controller {

  static targets = [
    'tokenAmountField',
    'priceAmountField',
    'tokenInput',
    'usdInput',
    'usdTextContainer',
    'tokenTextContainer',
    'tokenText',
    'tokenPriceAmountField',
    'tokenPrice',
    'tokenPriceX',
    'usdText'
  ];

  declare readonly tokenAmountFieldTarget: HTMLInputElement;
  declare readonly priceAmountFieldTarget: HTMLInputElement;
  declare readonly tokenPriceAmountFieldTarget: HTMLInputElement;
  declare readonly tokenPriceXTarget: HTMLSpanElement;
  declare readonly usdTextTarget: HTMLSpanElement;
  declare readonly tokenTextTarget: HTMLSpanElement;
  declare readonly hasTokenAmountFieldTarget: boolean;
  declare readonly hasPriceAmountFieldTarget: boolean;
  declare readonly hasTokenPriceAmountFieldTarget: boolean;
  declare readonly hasTokenPriceXTarget: boolean;
  declare readonly hasTokenInputTarget: boolean;
  declare readonly tokenInputTarget: HTMLDivElement;
  declare readonly hasUsdInputTarget: boolean;
  declare readonly usdInputTarget: HTMLDivElement;
  declare readonly hasUsdTextContainerTarget: boolean;
  declare readonly hasUsdTextTarget: boolean;
  declare readonly usdTextContainerTarget: HTMLDivElement;
  declare readonly hasTokenTextContainerTarget: boolean;
  declare readonly hasTokenTextTarget: boolean;
  declare readonly tokenTextContainerTarget: HTMLDivElement;

  static values = {
    coingeckoUnitPrice: Number,
    tokenTicker: String,
    contributableTicker: String,
    display: { type: String, default: 'usd' },
    maxTokens: Number,
  };

  declare readonly coingeckoUnitPriceValue: number;
  declare readonly tokenTickerValue: string;
  declare readonly contributableTickerValue: string;
  declare readonly hasCoingeckoUnitPriceValue: boolean;
  declare displayValue: string;
  declare readonly hasDisplayValue: boolean;
  declare readonly maxTokensValue: number;
  declare readonly hasMaxTokensValue: boolean;

  initialize() {
    this.estimate = debounce(this.estimate.bind(this), 300);
  }

  connect() {
    this.swapDisplay();
  }

  async estimate(e: any) {
    e.preventDefault();

    this.setValues(e.target);
  }

  setMax() {
    if(this.hasMaxTokensValue) {
      if(this.hasTokenAmountFieldTarget) {
        this.tokenAmountFieldTarget.value = this.maxTokensValue.toString();
        this.setValues(this.tokenAmountFieldTarget);
      }
    }
  }

  swapDisplay() {
    if(this.displayValue === 'token') {
      this.displayValue = 'usd';
      this.tokenInputTarget.classList.add('hidden');
      this.usdInputTarget.classList.remove('hidden');
      this.tokenTextContainerTarget.classList.remove('hidden');
      this.usdTextContainerTarget.classList.add('hidden');
    } else {
      this.displayValue = 'token';
      this.tokenInputTarget.classList.remove('hidden');
      this.usdInputTarget.classList.add('hidden');
      this.tokenTextContainerTarget.classList.add('hidden');
      this.usdTextContainerTarget.classList.remove('hidden');
    }
  }

  async setValues(target: HTMLInputElement) {
    if(this.hasTokenAmountFieldTarget && this.tokenAmountFieldTarget.value === '' && !this.tokenInputTarget.classList.contains('hidden')) {
      if(this.hasPriceAmountFieldTarget) {
        this.priceAmountFieldTarget.value = '';
        this.usdTextTarget.textContent = '0';
        this.usdTextTarget.classList.remove('text-white');
        this.usdTextTarget.classList.add('text-gray-300');
      }
      return
    }

    if(this.hasPriceAmountFieldTarget && this.priceAmountFieldTarget.value === '' && !this.usdInputTarget.classList.contains('hidden')) {
      if(this.hasTokenAmountFieldTarget) {
        this.tokenAmountFieldTarget.value = '';
        this.tokenTextTarget.textContent = '0';
        this.tokenTextTarget.classList.remove('text-white');
        this.tokenTextTarget.classList.add('text-gray-300');
      }
      return
    }

    const values = {
      coingeckoUnitPrice: this.coingeckoUnitPriceValue,
      tokenAmount: parseFloat(this.tokenAmountFieldTarget.value),
      priceAmount: parseFloat(this.priceAmountFieldTarget.value),
      tokenPriceAmount: parseFloat(this.tokenPriceAmountFieldTarget.value),
    };
    if (
      !!values.priceAmount &&
      !!values.tokenPriceAmount &&
      (target === this.tokenPriceAmountFieldTarget ||
        target === this.priceAmountFieldTarget)
    ) {
      values.tokenAmount = 0;
    }
    const { tokenPriceAmount, tokenAmount, priceAmount, tokenPriceX } =
      estimateTokenPriceValues(values);

    if(this.hasUsdTextTarget) {
      this.usdTextTarget.textContent = numberWithPrecision(priceAmount || 0);
      if(priceAmount) {
        this.usdTextTarget.classList.remove('text-gray-300');
        this.usdTextTarget.classList.add('text-white');
      } else {
        this.usdTextTarget.classList.remove('text-white');
        this.usdTextTarget.classList.add('text-gray-300');
      }
    }

    if(this.hasTokenTextTarget){
      const tAmount = values.tokenAmount || tokenAmount || 0;
      this.tokenTextTarget.textContent = numberWithPrecision(tAmount);
      if(tAmount > 0) {
        this.tokenTextTarget.classList.remove('text-gray-300');
        this.tokenTextTarget.classList.add('text-white');
      } else {
        this.tokenTextTarget.classList.remove('text-white');
        this.tokenTextTarget.classList.add('text-gray-300');
      }
    }

    if (
      target !== this.tokenAmountFieldTarget &&
      this.hasTokenAmountFieldTarget &&
      !!tokenAmount
    ) {
      this.tokenAmountFieldTarget.value = numberWithPrecision(tokenAmount);
    }

    if (
      target !== this.priceAmountFieldTarget &&
      this.hasPriceAmountFieldTarget &&
      !!priceAmount
    ) {
      this.priceAmountFieldTarget.value = numberWithPrecision(priceAmount);
    }

    if (
      target !== this.tokenPriceAmountFieldTarget &&
      this.hasTokenPriceAmountFieldTarget &&
      !!tokenPriceAmount
    ) {
      this.tokenPriceAmountFieldTarget.value = numberWithPrecision(tokenPriceAmount);
    }
    if (this.hasTokenPriceXTarget && tokenPriceX) {
      let sign = '';
      if (tokenPriceX > 0) {
        sign = '+';
      } else if (tokenPriceX < 0) {
        sign = '';
      }
      this.tokenPriceXTarget.textContent = ` (${sign}${tokenPriceX}%)`;
    }
  }
}
