import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("turbo:submit-end", this.handleSubmit.bind(this))
  }

  disconnect() {
    this.element.removeEventListener("turbo:submit-end", this.handleSubmit.bind(this))
  }

  handleSubmit(event) {
    if (event.detail.success) {
      this.clearForm()
    }
  }

  clearForm() {
    // Find all input fields except hidden, submit, button, and checkbox, and select
    // Select boxes are explicitly excluded from this query
    const inputs = this.element.querySelectorAll(
      'input:not([type="hidden"]):not([type="submit"]):not([type="button"]):not([type="checkbox"]), textarea:not(select)'
    )

    // Clear each input
    inputs.forEach(input => {
      input.value = ""
    })
  }
}
